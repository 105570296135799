import React from "react";
import { graphql } from 'gatsby';
import Header from '../component/Header';
import Footer from '../component/Footer';
import SEO from '../component/SEO';
import innertext from "innertext";

const BlogPostLayout = ({data}) => {
  const {title, content, excerpt, featured_media, categories} = data.wordpressPost;
  return ( 
    <div>
      <SEO 
        title={innertext(title)}
        description={innertext(excerpt)}
        image={featured_media.source_url}
        keywords={categories.map(result => result.name).join(', ')}
      />
      <Header title="Home"/>
      <main>
        <div className="container">
          <div className="row justify-content-md">
            <h1 dangerouslySetInnerHTML={{__html: title}} />
            <div dangerouslySetInnerHTML={{__html: content}} />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default BlogPostLayout;


export const query = () => graphql`
query($slug: String!) {
  wordpressPost(slug: {eq:$slug}){
      content
      title
      featured_media {
        source_url
      }
      categories {
        name
      }
      excerpt
    }
}`